<div class="ledge-app">
  <mat-toolbar color="accent" [ngClass]="isHome() ? 'app-header' : ''">
    <mat-toolbar-row>
      <button mat-button class="button-logo" routerLink="/home">
        <img src="/assets/images/logo.svg" alt="logo" class="logo">
      </button>
      <span class="spacer"></span>
      <div class="right" fxShow="true" fxHide.lt-md="true">
        <ng-template *ngTemplateOutlet="navMenu"></ng-template>
      </div>

      <div fxHide="true" fxShow.lt-md="true">
        <button mat-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>


  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav fxLayout="column">
      <div fxLayout="column" class="ledge-side-menu">
        <ng-template *ngTemplateOutlet="navMenu"></ng-template>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #navMenu>
  <button mat-button routerLink="/home" routerLinkActive="active">{{ 'home' | translate}}</button>
  <button mat-button routerLink="/case-study" class="intro-case" routerLinkActive="active"
          matTooltip="{{ 'tips.case-study' | translate}}">
    {{ 'case-study' | translate}}
  </button>
  <button mat-button routerLink="/pattern" class="intro-pattern" routerLinkActive="active"
          matTooltip="{{ 'tips.patterns' | translate}}">
    {{ 'patterns' | translate}}
  </button>

  <mat-menu #practiseMenu="matMenu" backdropClass="nav-menu-wrapper">
    <button mat-menu-item routerLink="/practise/devops-practise" routerLinkActive="active">
      {{ 'practise-page.devops' | translate}}</button>
    <button mat-menu-item routerLink="/practise/test-practise" routerLinkActive="active">
      {{ 'practise-page.test' | translate}}</button>
    <button mat-menu-item routerLink="/practise/agile-practise" routerLinkActive="active">
      {{ 'practise-page.agile' | translate}}
    </button>
    <button mat-menu-item routerLink="/practise/frontend-devops-practise" routerLinkActive="active">
      {{ 'practise-page.frontend-devops-practise' | translate}}
    </button>
    <button mat-menu-item routerLink="/practise/tech-practise" routerLinkActive="active">
      {{ 'practise-page.tech' | translate}}
    </button>
    <button mat-menu-item routerLink="/practise/devops-platform" routerLinkActive="active">
      {{ 'practise-page.platform' | translate}}
    </button>
    <button mat-menu-item routerLink="/practise/ci-practise" routerLinkActive="active">
      {{ 'practise-page.ci' | translate}}
    </button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="practiseMenu" class="intro-practise"
          matTooltip="{{ 'tips.practises' | translate}}">
    {{ 'practises' | translate}}<mat-icon>arrow_drop_down</mat-icon>
  </button>

  <button mat-button routerLink="/manual" class="intro-manual" routerLinkActive="active"
          matTooltip="{{ 'tips.manual' | translate}}" id="step4">{{ 'manual' | translate}}</button>
  <button mat-button routerLink="/solution" routerLinkActive="active" matTooltip="{{ 'tips.solution' | translate}}">
    {{ 'solution' | translate}}
  </button>

  <mat-menu #tankMenu="matMenu" backdropClass="nav-menu-wrapper">
    <button mat-menu-item routerLink="/think-tank/ba" routerLinkActive="active">
      {{ 'tank-page.requirements' | translate}}</button>
    <button mat-menu-item routerLink="/think-tank/qa" routerLinkActive="active">
      {{ 'tank-page.qa' | translate}}</button>
    <button mat-menu-item routerLink="/think-tank/mobile-android" routerLinkActive="active">
      Android
    </button>
    <button mat-menu-item routerLink="/think-tank/frontend" routerLinkActive="active">
      {{ 'tank-page.frontend' | translate}}
    </button>
    <button mat-menu-item routerLink="/think-tank/ops" routerLinkActive="active">
      {{ 'tank-page.ops' | translate}}
    </button>
    <button mat-menu-item routerLink="/think-tank/backend" routerLinkActive="active">
      {{ 'tank-page.backend' | translate}}
    </button>
    <button mat-menu-item routerLink="/think-tank/refactoring" routerLinkActive="active">
      {{ 'tank-page.refactoring' | translate}}
    </button>
    <button mat-menu-item routerLink="/think-tank/microservices" routerLinkActive="active">
      {{ 'tank-page.microservices' | translate}}
    </button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="tankMenu" matTooltip="{{ 'tips.think-tank' | translate}}">
    {{ 'think-tank' | translate}}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #toolsetMenu="matMenu" backdropClass="nav-menu-wrapper">
    <button mat-menu-item routerLink="/maturity" routerLinkActive="active">{{ 'maturity' | translate}}</button>
    <button mat-menu-item routerLink="/design" routerLinkActive="active">{{ 'design-devops' | translate}}</button>
    <button mat-menu-item routerLink="/checklists" routerLinkActive="active">{{ 'checklists' | translate}}</button>
    <button mat-menu-item routerLink="/skilltree" routerLinkActive="active">{{ 'skilltree' | translate}}</button>
    <button mat-menu-item routerLink="/tool" routerLinkActive="active">{{ 'analysis-tools' | translate}}</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="toolsetMenu" class="intro-tool" matTooltip="{{ 'tips.tool' | translate}}">
    {{ 'tool' | translate}}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #appMenu="matMenu" backdropClass="nav-menu-wrapper">
    <button mat-menu-item routerLink="/report" routerLinkActive="active">{{ 'report' | translate}}</button>
    <button mat-menu-item routerLink="/job" routerLinkActive="active">{{ 'job' | translate}}</button>
    <button mat-menu-item routerLink="/helper" routerLinkActive="active">{{ 'ledge-editor' | translate}}</button>
    <button mat-menu-item routerLink="/resources" routerLinkActive="active">{{ 'resources' | translate}}</button>
    <button mat-menu-item routerLink="/guide" routerLinkActive="active">{{ 'guide' | translate}}</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="appMenu" matTooltip="{{ 'tips.resources' | translate}}">
    {{ 'resource' | translate}}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #langMenu="matMenu" backdropClass="nav-menu-wrapper">
    <button mat-menu-item (click)="setLanguage('zh-cn')">中文</button>
    <button mat-menu-item (click)="setLanguage('en')">English (need help)</button>
    <a class="mat-menu-item" mat-menu-item href="https://github.com/phodal/ledge/issues" target="_blank">Add more
      lang</a>
  </mat-menu>

  <button mat-button (click)="openLink('https://zhuanlan.zhihu.com/bizdevops')">{{'blog' | translate}}</button>

  <button mat-button [matMenuTriggerFor]="langMenu">
    <mat-icon>language</mat-icon>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</ng-template>
